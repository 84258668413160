import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlineRounded';
// import ShoppingIcon from '@material-ui/icons/ShoppingBasketOutlined';
import ShoppingIcon from '@material-ui/icons/QuestionAnswerTwoTone';
import LockIcon from '@material-ui/icons/LockOpenOutlined';
import TextIcon from '@material-ui/icons/TextFieldsOutlined';
import ImageIcon from '@material-ui/icons/ImageAspectRatioOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

// Component styles
import styles from './styles';

import logo from 'images/logos/reciclabc_logo_black.svg'
import avatar from 'images/avatars/avatar_1.png'
import { client } from '../../../../App';
import gql from 'graphql-tag';

export const PROFILE_QUERY = gql`
  query ME{
    me{
      id
      _id
      email
      name
      picture
    }
  }
`

class Sidebar extends Component {
  state = {
    me: null,
    isLoading: false,
    showAll: false
  }

  async getCurrentUser() {
    try {
      this.setState({ isLoading: true });      
      const { loading, error, data: { me } } = await client.query({
        query: PROFILE_QUERY
      })

      if(error) {
        this.setState({
          isLoading: loading,
          error
        });
      }
      if (me) {
        this.setState({
          isLoading: loading,
          me
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error
      });
      
    }
  }

  componentDidMount() {
    this.getCurrentUser();
  }
  
  render() {
    const { me, isLoading, showAll } = this.state;
    const { classes, className } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link
            className={classes.logoLink}
            to="/"
          >
            <img
              alt="reciclabc logo"
              className={classes.logoImage}
              src={logo}
            />
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        {!isLoading && me != null && 
        <div className={classes.profile}>
          <Link to="/account">
            <Avatar
              alt="Roman Kutepov"
              className={classes.avatar}
              src={me.picture ? me.picture : avatar}
            />
          </Link>
          <Typography
            className={classes.nameText}
            variant="h6"
          >
            {me.name || ''}
          </Typography>
          <Typography
            className={classes.bioText}
            variant="caption"
          >
            Brain Director
          </Typography>
        </div>
        }

        <Divider className={classes.profileDivider} />
        <List
          component="div"
          disablePadding
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/dashboard"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/users"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Usuários"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/questions"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ShoppingIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Perguntas"
            />
          </ListItem>
          { showAll && 
          <React.Fragment>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/question"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ShoppingIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Add Question"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/products"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ShoppingIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Products"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/sign-in"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <LockIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Authentication"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/typography"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <TextIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Typography"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/icons"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Icons and Images"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/account"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AccountBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Account"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/settings"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Settings"
              />
            </ListItem>
          </React.Fragment>
          }
        
        </List>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader className={classes.listSubheader}>
              Rotas Experimentais
            </ListSubheader>
          }
        >
          <ListItem
            className={classes.listItem}
            component="a"
            href="https://stqpublicidade.com.br"
            target="_blank"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Customer support"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/experimental/users"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Users"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/experimental/questions"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ShoppingIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Perguntas"
            />
          </ListItem>
        </List>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
