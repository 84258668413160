import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Views
import Dashboard from './views/Dashboard';
import ProductList from './views/ProductList';
import UserList from './views/UserList';
import UserListExperimental from './views/UserListExperimental';
import Typography from './views/Typography';
import Icons from './views/Icons';
import Account from './views/Account';
import AddQuestion from './views/AddQuestion';
import Questions from './views/Questions';
import QuestionsExperimental from './views/QuestionsExperimental';
import Settings from './views/Settings';
import SignUp from './views/SignUp';
import SignIn from './views/SignIn';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';

import { PrivateRedirect, PrivateRoute } from './PrivateRoute';

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <PrivateRedirect
          exact
          from="/"
          to="/dashboard"
        />
        {/* <PrivateRedirect
            exact
            from="/sign-in"
            to="/dashboard"
          /> */}
        {/* <Redirect
          exact
          from="/"
          to="/dashboard"
        /> */}
        <Route
          component={SignIn}
          exact
          path="/sign-in"
        />
        <PrivateRoute
          component={Dashboard}
          exact
          path="/dashboard"
        />
        <PrivateRoute
          component={UserList}
          exact
          path="/users"
        />
        <PrivateRoute
          component={AddQuestion}
          exact
          path="/question"
        />
        <PrivateRoute
          component={Questions}
          exact
          path="/questions"
        />
        <PrivateRoute
          component={ProductList}
          exact
          path="/products"
        />
        <PrivateRoute
          component={Typography}
          exact
          path="/typography"
        />
        <PrivateRoute
          component={Icons}
          exact
          path="/icons"
        />
        <PrivateRoute
          component={Account}
          exact
          path="/account"
        />
        <PrivateRoute
          component={Settings}
          exact
          path="/settings"
        />
        <PrivateRoute
          component={SignUp}
          exact
          path="/sign-up"
        />
        <PrivateRoute
          component={UnderDevelopment}
          exact
          path="/under-development"
        />
        <PrivateRoute
          component={UserListExperimental}
          exact
          path="/experimental/users"
        />
        <PrivateRoute
          component={QuestionsExperimental}
          exact
          path="/experimental/questions"
        />
        <Route
          component={NotFound}
          exact
          path="/not-found"
        />
        <Redirect to="/not-found" />
      </Switch>
    );
  }
}
