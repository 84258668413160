import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusIcon from '@material-ui/icons/Add';

// Shared components
import SearchInput from 'components/SearchInput';
import DisplayMode from 'components/DisplayMode';

// Component styles
import styles from './styles';

function Toolbar({ classes, className, selectedItems, deleteFunction, history, refresh, addPath }) {
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
        />
        <span className={classes.spacer} />
        {selectedItems.length > 0 && (
          <IconButton
            className={classes.deleteButton}
            onClick={deleteFunction}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {addPath && (
          <IconButton
            onClick={() => history.push(addPath)}
          >
            <PlusIcon />
          </IconButton>
        )}
        <Button
          className={classes.importButton}
          size="small"
        >
          <ArrowDownwardOutlinedIcon className={classes.importIcon} /> Export
        </Button>
        <Button
          className={classes.exportButton}
          onClick={() => refresh()}
          size="small"
        >
          <RefreshIcon className={classes.exportIcon} />
            Refresh
        </Button>
        <DisplayMode mode="list" />
      </div>
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  selectedItems: PropTypes.array
};

Toolbar.defaultProps = {
  selectedItems: []
};

export default withStyles(styles)(withRouter(Toolbar));
