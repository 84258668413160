import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

// Externals
import { Chart } from 'react-chartjs-2';

// Material helpers
import { MuiThemeProvider } from '@material-ui/core/styles';

// ChartJS helpers
import { draw } from './helpers/chartjs';

// Theme
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

// Routes
import Routes from './Routes';
import { API } from './consts';
// Browser history
const browserHistory = createBrowserHistory();

const httpLink = createHttpLink({
  uri: API
});

const authMiddleware = setContext(async (req, { headers }) => {
  const token = await localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? token : ''
    }
  };
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache,
});
// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, { draw });

export default class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </MuiThemeProvider>
      </ApolloProvider>
    );
  }
}
