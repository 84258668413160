import React, { PureComponent } from 'react';
// import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
// import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';

// Shared helpers
// import getInitials from 'helpers/getInitials';

// Shared components
import Portlet from 'components/Portlet';
import PortletContent from 'components/PortletContent';

// Component styles
import styles from './styles';
import { Chip } from '@material-ui/core';

class QuestionsTable extends PureComponent {
  state = {
    selectedQuestions: [],
    rowsPerPage: 10,
    page: 0
  };

  handleSelectAll = event => {
    const { questions, onSelect } = this.props;

    let selectedQuestions;

    if (event.target.checked) {
      selectedQuestions = questions.map(q => q._id);
    } else {
      selectedQuestions = [];
    }

    this.setState({ selectedQuestions });

    onSelect(selectedQuestions);
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props;
    const { selectedQuestions } = this.state;

    const selectedIndex = selectedQuestions.indexOf(id);
    let newSelectedQuestions = [];

    if (selectedIndex === -1) {
      newSelectedQuestions = newSelectedQuestions.concat(selectedQuestions, id);
    } else if (selectedIndex === 0) {
      newSelectedQuestions = newSelectedQuestions.concat(selectedQuestions.slice(1));
    } else if (selectedIndex === selectedQuestions.length - 1) {
      newSelectedQuestions = newSelectedQuestions.concat(selectedQuestions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedQuestions = newSelectedQuestions.concat(
        selectedQuestions.slice(0, selectedIndex),
        selectedQuestions.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedQuestions: newSelectedQuestions });

    onSelect(newSelectedQuestions);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, className, questions, show } = this.props;
    const { activeTab, selectedQuestions, rowsPerPage, page } = this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    size="small"
                  >
                    <Checkbox
                      checked={selectedQuestions.length === questions.length}
                      color="primary"
                      indeterminate={
                        selectedQuestions.length > 0 &&
                        selectedQuestions.length < questions.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  {show._id && <TableCell align="left">ID</TableCell>}
                  {show.introduction && <TableCell align="left">Intro</TableCell>}
                  {show.label && <TableCell align="left">Pergunta</TableCell>}
                  {show.answers && <TableCell align="left">Respostas</TableCell>}
                  {show.correctAnswer && <TableCell align="left">Correta</TableCell>}
                  {show.level && <TableCell align="left">Question level</TableCell>}
                  {show.createdAt && <TableCell align="left">Registration date</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {questions
                  .filter(question => {
                    if (activeTab === 1) {
                      return !question.returning;
                    }

                    if (activeTab === 2) {
                      return question.returning;
                    }

                    return question;
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(({node}) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={node._id}
                      selected={selectedQuestions.indexOf(node._id) !== -1}
                    >
                      <TableCell
                        className={classes.tableCell}
                        size="small"
                      >
                        <div
                          className={classes.tableCellInner}
                        >
                          <Checkbox
                            checked={selectedQuestions.indexOf(node._id) !== -1}
                            color="primary"
                            onChange={event =>
                              this.handleSelectOne(event, node._id)
                            }
                            value="true"
                          />
                        </div>
                      </TableCell>
                      {show._id &&  <TableCell className={classes.tableCell}>
                        {node._id}
                      </TableCell>}
                      {show.introduction &&  <TableCell className={classes.tableCell}>
                        {node.introduction}
                      </TableCell>}
                      {show.label && <TableCell className={classes.tableCell}>
                        {node.label}
                      </TableCell>}
                      {show.answers && <TableCell className={classes.tableCell}>
                        <ul>
                          {node.answers.map((an, index) => 
                            <li
                              key={index}
                              style={index + 1 === node.correctAnswer ? { backgroundColor: '#00ffbf66' }: {}}
                            >
                              {an}  
                            </li>
                          )}
                        </ul>
                      </TableCell>}
                      {show.correctAnswer &&  <TableCell className={classes.tableCell}>
                        {node.correctAnswer}
                      </TableCell>}
                      {show.level && <TableCell className={classes.tableCell}>
                        <Chip
                          className={classes.chip}
                          color="primary"
                          label={node.level}
                          size="small"
                          variant="outlined"
                        />
                      </TableCell>}
                      {show.createdAt &&  <TableCell className={classes.tableCell}>
                        {moment(node.createdAt).format('DD/MM/YYYY')}
                      </TableCell>}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            component="div"
            count={questions.length}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

QuestionsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  questions: PropTypes.array.isRequired,
  show: PropTypes.object
};

QuestionsTable.defaultProps = {
  questions: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

export default withStyles(styles)(QuestionsTable);
