// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// // Externals
// import classNames from 'classnames';
// import PropTypes from 'prop-types';
// import PerfectScrollbar from 'react-perfect-scrollbar';

// // Material helpers
// import { withStyles } from '@material-ui/core/styles';

// // Material components
// import Avatar from '@material-ui/core/Avatar';
// import Checkbox from '@material-ui/core/Checkbox';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
// import TablePagination from '@material-ui/core/TablePagination';

// // Shared helpers
// import getInitials from 'helpers/getInitials';

// // Shared components
// import Portlet from 'components/Portlet';
// import PortletContent from 'components/PortletContent';

// // Component styles
// import styles from './styles';
// import { Chip } from '@material-ui/core';

// class UsersTable extends Component {
//   state = {
//     selectedUsers: [],
//     rowsPerPage: 10,
//     page: 0
//   };

//   handleSelectAll = event => {
//     const { users, onSelect } = this.props;

//     let selectedUsers;

//     if (event.target.checked) {
//       selectedUsers = users.map(user => user._id);
//     } else {
//       selectedUsers = [];
//     }

//     this.setState({ selectedUsers });

//     onSelect(selectedUsers);
//   };

//   handleSelectOne = (event, id) => {
//     const { onSelect } = this.props;
//     const { selectedUsers } = this.state;

//     const selectedIndex = selectedUsers.indexOf(id);
//     let newSelectedUsers = [];

//     if (selectedIndex === -1) {
//       newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
//     } else if (selectedIndex === 0) {
//       newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
//     } else if (selectedIndex === selectedUsers.length - 1) {
//       newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelectedUsers = newSelectedUsers.concat(
//         selectedUsers.slice(0, selectedIndex),
//         selectedUsers.slice(selectedIndex + 1)
//       );
//     }

//     this.setState({ selectedUsers: newSelectedUsers });

//     onSelect(newSelectedUsers);
//   };

//   handleChangePage = (event, page) => {
//     this.setState({ page });
//   };

//   handleChangeRowsPerPage = event => {
//     this.setState({ rowsPerPage: event.target.value });
//   };

//   render() {
//     const { classes, className, users, show } = this.props;
//     const { activeTab, selectedUsers, rowsPerPage, page } = this.state;
//     const rootClassName = classNames(classes.root, className);

//     return (
//       <Portlet className={rootClassName}>
//         <PortletContent noPadding>
//           <PerfectScrollbar>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell align="left">
//                     <Checkbox
//                       checked={selectedUsers.length === users.length}
//                       color="primary"
//                       indeterminate={
//                         selectedUsers.length > 0 &&
//                         selectedUsers.length < users.length
//                       }
//                       onChange={this.handleSelectAll}
//                     />
//                     Name
//                   </TableCell>
//                   {show._id && <TableCell align="left">ID</TableCell>}
//                   {show.cpf && <TableCell align="left">CPF</TableCell>}
//                   {show.email && <TableCell align="left">E-mail</TableCell>}
//                   {show.points && <TableCell align="left">Pontuação</TableCell>}
//                   {show.cupoms && <TableCell align="left">nº de Cupoms</TableCell>}
                  
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {users
//                   .filter(user => {
//                     if (activeTab === 1) {
//                       return !user.returning;
//                     }

//                     if (activeTab === 2) {
//                       return user.returning;
//                     }

//                     return user;
//                   })
//                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                   .map(({node}) => (
//                     <TableRow
//                       className={classes.tableRow}
//                       hover
//                       key={node._id}
//                       selected={selectedUsers.indexOf(node._id) !== -1}
//                     >
//                       <TableCell className={classes.tableCell}>
//                         <div className={classes.tableCellInner}>
//                           <Checkbox
//                             checked={selectedUsers.indexOf(node._id) !== -1}
//                             color="primary"
//                             onChange={event =>
//                               this.handleSelectOne(event, node._id)
//                             }
//                             value="true"
//                           />
//                           <Avatar
//                             className={classes.avatar}
//                             src={node.picture}
//                           >
//                             {getInitials(node.name)}
//                           </Avatar>
//                           <Link to="#">
//                             <Typography
//                               className={classes.nameText}
//                               variant="body1"
//                             >
//                               {node.name}
//                             </Typography>
//                             {!node.active && 
//                               <Chip
//                                 className={classes.chip}
//                                 label="Inativo"
//                                 size="small"
//                               />
//                             }
//                           </Link>
//                         </div>
//                       </TableCell>
//                       {show._id && 
//                         <TableCell className={classes.tableCell}>
//                           {node._id}
//                         </TableCell>
//                       }
//                       {show.cpf && 
//                         <TableCell className={classes.tableCell}>
//                           {node.cpf}
//                         </TableCell>
//                       }
//                       {show.email && 
//                         <TableCell className={classes.tableCell}>
//                           {node.email}
//                         </TableCell>
//                       }
//                       {show.points && 
//                         <TableCell className={classes.tableCell}>
//                           {node.points.points > 0 ? 
//                             <Chip
//                               className={classes.chip}
//                               color="secondary"
//                               label={node.points.points}
//                               size="small"
//                               variant="outlined"
//                             /> : ''
//                           }
//                         </TableCell>
//                       }
//                       {show.cupoms && 
//                         <TableCell className={classes.tableCell}>
//                           {node.cupoms.count}
//                         </TableCell>
//                       }
//                     </TableRow>
//                   ))}
//               </TableBody>
//             </Table>
//           </PerfectScrollbar>
//           <TablePagination
//             backIconButtonProps={{
//               'aria-label': 'Previous Page'
//             }}
//             component="div"
//             count={users.length}
//             nextIconButtonProps={{
//               'aria-label': 'Next Page'
//             }}
//             onChangePage={this.handleChangePage}
//             onChangeRowsPerPage={this.handleChangeRowsPerPage}
//             page={page}
//             rowsPerPage={rowsPerPage}
//             rowsPerPageOptions={[5, 10, 25]}
//           />
//         </PortletContent>
//       </Portlet>
//     );
//   }
// }

// UsersTable.propTypes = {
//   className: PropTypes.string,
//   classes: PropTypes.object.isRequired,
//   onSelect: PropTypes.func,
//   onShowDetails: PropTypes.func,
//   users: PropTypes.array.isRequired
// };

// UsersTable.defaultProps = {
//   users: [],
//   onSelect: () => {},
//   onShowDetails: () => {}
// };

// export default withStyles(styles)(UsersTable);


import React from 'react';
import MUIDataTable from 'mui-datatables';

function flatten(input, reference, output) {
  output = output || {};
  for (var key in input) {
    var value = input[key];
    key = reference ? reference + '.' + key : key;
    if (typeof value === 'object' && value !== null) {
      flatten(value, key, output);
    } else {
      output[key] = value;
    }
  }
  return output;
}

function UsersTable({ users }) {
  // const columns = Object.keys(users);
  // const columns = ['Nome', 'CPF', 'E-mail', 'Pontos', 'Salary', 'Avatar', 'Cupoms'];

  function changePage(value) {
    // console.log('== change page', value)
  }

  // function updateValue(value) {
  //   console.log('== updateValue page', value)
  // }

  const columns = [
    {
      name: 'Nome',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <p
        //       change={event => updateValue(event)}
        //       index={tableMeta.columnIndex}
        //       value={value}
        //     >value</p>
        //   );
        // }
      }
    },
    {
      name: 'CPF',
      options: {
        filter: true
      }
    },
    {
      name: 'Email',
      options: {
        filter: true
      }
    },
    {
      name: 'Pontos',
      options: {
        filter: true
      }
    },
  ]
  
  const x = users.map(({ node }) => flatten(node))
  const xx = x.map(( y ) => Object.values(y))

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    // count: count,
    // page: page,
    rowsPerPage: 2,
    // page: 1,
    onTableChange: (action, tableState) => {

      console.log(action, tableState);
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          console.log('search', tableState.searchText)
          break;
      }
    }
  };

  return (
    <MUIDataTable
      columns={columns}
      data={xx}
      options={options}
      title={'Perguntas'}
    />
  );
}

// export default withStyles(styles)(UsersTable);
export default UsersTable;
