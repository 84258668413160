import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import decode from 'jwt-decode';

export const chackAuth = () => {
  // console.log('chackAuth')
  const token = localStorage.getItem('token');
  // const refreshToken = localStorage.getItem('refreshToken');
  // if (!token || !refreshToken) {
  if (!token || token == null) {
    return false;
  }

  // try {
  //   // { exp: 123123123123 }
  //   const { exp } = decode(refreshToken)

  //   if (exp > new Date().getTime() / 1000) {
  //     // 
  //   }
  // } catch {
  //   return false
  // }

  return true
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      chackAuth()
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/sign-in',
            state: { from: props.location }
          }}
        />
    )}
  />
)

export const PrivateRedirect = ({ component: Component, ...rest }) => (
  <Redirect
    {...rest}
    render={(props) => (
      chackAuth()
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/sign-in',
            state: { from: props.location }
          }}
        />
    )}
  />
)
