import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';

// Shared components
import Portlet from 'components/Portlet';
import PortletHeader from 'components/PortletHeader';
import PortletLabel from 'components/PortletLabel';
import PortletContent from 'components/PortletContent';
import PortletFooter from 'components/PortletFooter';
// Component styles
import styles from './styles';
import { GET_QUESTIONS } from '../../../Questions';

const ADD_QUESTION = gql`
  mutation ADD_QUESTION($label: String!, $level: String!, $answers: [String]!, $correctAnswer: Int!) {
    QuestionAdd(input: {
      label: $label,
      level: $level,
      answers: $answers,
      correctAnswer: $correctAnswer,
    }) {
      error
    }
  }
`

const states = [
  {
    value: 'SOFT',
    label: 'Facil'
  },
  {
    value: 'MEDIUM',
    label: 'Mediana'
  },
  {
    value: 'HARD',
    label: 'Dificil'
  },
  {
    value: 'GOLD',
    label: 'Pergunta de ouro'
  }
];

const INITIAL_STATE = {
  introduction: '',
  label: '',
  correctAnswer: 1,
  level: 'SOFT',
  answers: [],
  states: 'SOFT',
}

class QuestionForm extends Component {
  state = {
    ...INITIAL_STATE
  };

  handleChange = e => this.setState({ level: e.target.value });

  handleFieldChange = (field, value) => this.setState({[field]: value });

  handleFieldAnswerChange = (index, value) => {
    const { answers } = this.state
    answers[index] = value;
    this.setState({ answers });
  }

  render() {
    const { classes, className, ...rest } = this.props;
    const { label, correctAnswer, level, answers } = this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <Mutation
        mutation={ADD_QUESTION}
        onCompleted={() => this.setState(INITIAL_STATE)}
        refetchQueries={() => [
          {
            query: GET_QUESTIONS,
          },
        ]}
      >
        {QuestionAdd => (
          <Portlet
            {...rest}
            className={rootClassName}
          >
            <PortletHeader>
              <PortletLabel
                subtitle="para marcar a resposta correta use o box antes da resposta"
                title="Pergunta"
              />
            </PortletHeader>
            <PortletContent noPadding>
              <form
                autoComplete="off"
                noValidate
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    className={classes.field}
                    style={{ flex: 1 }}>
                    <TextField
                      className={classes.textField}
                      label="Selecione a dificuldade"
                      margin="dense"
                      onChange={this.handleChange}
                      required
                      select
                      // eslint-disable-next-line
                      SelectProps={{
                        native: true
                      }}
                      value={level}
                      variant="outlined"
                    >
                      {states.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      className={classes.textField}
                      helperText={label.length > 0 ? `Número de toques ${label.length}`: ''}
                      label="Pergunta"
                      margin="dense"
                      multiline
                      onChange={event =>
                        this.handleFieldChange('label', event.target.value)
                      }
                      required
                      rows={7}
                      value={label}
                      variant="outlined"
                    />
                  </div>

                  <div
                    className={classes.field}
                    style={{ flex: 2 }}>
                    {[1, 2, 3, 4].map((index) => 
                      <div
                        key={index}
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        <Radio
                          checked={correctAnswer === index}
                          name="correctAnswer"
                          onChange={() => this.setState({ correctAnswer: index })}
                          variant="secondary"/>
                          
                        <TextField
                          className={classes.textField}
                          label={`Resposta ${index}`}
                          margin="dense"
                          onChange={event =>
                            this.handleFieldAnswerChange(index, event.target.value)
                          }
                          required
                          value={answers[index]}
                          variant="outlined"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </form>

           
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
              <Button
                color="primary"
                onClick={async e => {
                  e.preventDefault();
                  const variables = { 
                    label,
                    correctAnswer,
                    level,
                    answers: answers.filter(Boolean),
                  }
                  await QuestionAdd({ variables });
                }}
                variant="contained"
              >
                Salvar
              </Button>
            </PortletFooter>
          </Portlet>
        )}
      </Mutation>
    );
  }
}

QuestionForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QuestionForm);
