import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import Grid from '@material-ui/core/Grid';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Custom components
import AccountProfile from './components/AccountProfile';
import AccountDetails from './components/AccountDetails';
import { PROFILE_QUERY } from '../../layouts/Dashboard/components/Sidebar';
import Password from './components/Password';
import Notifications from './components/Notifications';

import { client } from '../../App';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  }
});

class Account extends Component {
  state = { tabIndex: 0, isLoading: false, me: null};

  async getCurrentUser() {
    try {
      this.setState({ isLoading: true });      
      const { loading, error, data: { me } } = await client.query({
        query: PROFILE_QUERY
      })

      if(error) {
        this.setState({
          isLoading: loading,
          error
        });
      }
      if (me) {
        this.setState({
          isLoading: loading,
          me
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error
      });
      
    }
  }

  componentDidMount() {
    this.getCurrentUser();
  }
  
  render() {
    const { isLoading, me } = this.state;
    const { classes } = this.props;

    return (
      <DashboardLayout title="Account">
        <div className={classes.root}>
          <Grid
            container
            spacing={32}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={4}
              xs={12}
            >{!isLoading && <AccountProfile me={me} />}
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xl={8}
              xs={12}
            >
              {!isLoading && <AccountDetails />}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={32}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={4}
              xs={12}
            >
              <Password />
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xl={8}
              xs={12}
            >
              <Notifications />
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
