import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Chip, withStyles } from '@material-ui/core';
import styles from './styles';

const rowsPerPage = 5;

function select(input, output) {
  output = [ input._id, input.label, { answers: input.answers, correctAnswer: input.correctAnswer }, input.level ]
  return output;
}

function QuestionsTable({ questions, classes, count, paginate }) {
  const [ page, setPage ] = React.useState(0)
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        filter: true,
        display: false,

      }
    },
    {
      name: 'Pergunta',
      options: {
        filter: true
      }
    },
    {
      name: 'Resposta',
      options: {
        filter: false,
        display: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: ({ answers, correctAnswer }) => {
          return (
            <ul>
              {answers.map((an, index) => 
                <li
                  key={index}
                  style={index + 1 === correctAnswer ? { backgroundColor: '#00ffbf66' }: {}}
                >
                  {an}  
                </li>
              )}
            </ul>
          );
        }
      }
    },
    {
      name: 'Dificuldade',
      options: {
        filter: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => {
          return (
            <Chip
              className={classes.chip}
              color="secondary"
              label={value}
              size="small"
              // variant="outlined"
            />
          );
        }
      }
    },
  ]

  function changePage(value, curCursor) {
    if (questions.length >= (count - rowsPerPage)) {
      return
    }

    curCursor = questions[value * rowsPerPage].cursor
    setPage(value)
    paginate(curCursor)
  }

  const x = questions.map(({ node }) => select(node))
  const data = x.map(( y ) => Object.values(y))

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    // serverSide: true,
    count,
    page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
      }
    }
  };

  return (
    <MUIDataTable
      columns={columns}
      data={data}
      options={options}
      title={'Perguntas'}
    />
  );
}

export default withStyles(styles)(QuestionsTable);
