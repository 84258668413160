import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Custom components
import UsersTable from './components/UsersTable';

// Component styles
import styles from './style';
import { client } from '../../App';
import gql from 'graphql-tag';
import Toolbar from '../../components/Toolbar';

export const GET_USERS = gql` {
  users(first: 25) {
    count
    edges {
      node {
        id
        _id
        name
        cpf
        points {
          points
        }
        picture
        cupoms {
          count
        }
        active
      }
    }
  }
}
`

export const DELETE_USER = gql`
  mutation DELETE_USER($_id: ID!) {
    UserDelete(input: {
      _id: $_id,
    }) {
      error
      deletedID
    }
  }
`

class UserList extends Component {
  signal = true;

  state = {
    isLoading: false,
    limit: 25,
    users: [],
    selectedUsers: [],
    error: null,
    // 
    _id: false,
    name: true,
    createdAt: false,
    points: true,
    picture: true,
    cupoms: true,
    cpf: true,
  };

  async getUsers() {
    try {
      this.setState({ isLoading: true });
      const { loading, error, data: { users } } = await client.query({
        query: GET_USERS
      })

      if(error) {
        this.setState({
          isLoading: loading,
          error
        });
      }
  
      if (users) {
        this.setState({
          isLoading: loading,
          users
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  componentDidMount() {
    this.signal = false;
    this.getUsers();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedUsers => this.setState({ selectedUsers });

  asyncDelete = async _id => {
    return await client.mutate({
      mutation: DELETE_USER,
      variables: { _id },
      refetchQueries: () => [
        {
          query: GET_USERS,
        },
      ],
    }).then(() => {
      this.setState({ selectedUsers: [] }, () => this.getUsers())
    })
  }

  deleteFunction = async () => Promise.all(this.state.selectedUsers.map(item => this.asyncDelete(item)))
  
  renderUsers() {
    const { classes } = this.props;
    const { 
      _id,
      name,
      points,
      picture,
      cupoms,
      cpf,

      isLoading, 
      users, 
      error,
      limit
    } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (users.length === 0) {
      return <Typography variant="h6">There are no users</Typography>;
    }

    return (
      <UsersTable
        count={users.count}
        limit={limit}
        onSelect={this.handleSelect}
        show={{
          _id,
          cpf,
          name,
          points,
          picture,
          cupoms,
        }}
        users={users.edges}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { selectedUsers } = this.state;

    return (
      <DashboardLayout title="Usuários">
        <div className={classes.root}>
          <Toolbar 
            deleteFunction={this.deleteFunction}
            refresh={() => this.getUsers()}
            selectedItems={selectedUsers}
          />
          <div className={classes.content}>{this.renderUsers()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

UserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
