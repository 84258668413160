import React, { PureComponent } from 'react';

// Externals
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
// Material helpers
import { withStyles } from '@material-ui/core/styles';

// Material components
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Shared layouts
import DashboardLayout from 'layouts/Dashboard';

// Custom components
import Toolbar from 'components/Toolbar';
import QuestionsTable from './components/QuestionsTable';

// Component styles
import styles from './style';
import { client } from '../../App';

export const GET_QUESTIONS = gql` {
  questions(first: 30) {
    count
    edges {
      node {
        id
        _id
        label
        level
        answers
        correctAnswer
      }
    }
  }
}
`

export const DELETE_QUESTION = gql`
  mutation DELETE_QUESTION($_id: ID!) {
    QuestionDelete(input: {
      _id: $_id,
    }) {
      error
      deletedID
    }
  }
`

class Questions extends PureComponent {
  signal = true;

  state = {
    isLoading: false,
    limit: 10,
    questions: [],
    selectedQuestions: [],
    error: null,
    // 
    _id: false,
    introduction: false,
    label: true,
    answers: true,
    correctAnswer: false,
    level: true,
    createdAt: false,
  };

  async getQuestions() {
    this.setState({ isLoading: true });
    try {
      const { loading, error, data: { questions } } = await client.query({
        query: GET_QUESTIONS
      })

      if(error) {
        this.setState({
          isLoading: loading,
          error
        });
      }
      
      if (this.signal) {
        this.setState({
          isLoading: false,
          questions
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  componentDidMount() {
    this.signal = true;
    this.getQuestions();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedQuestions => this.setState({ selectedQuestions });

  asyncDelete = async _id => {
    return await client.mutate({
      mutation: DELETE_QUESTION,
      variables: { _id },
      refetchQueries: () => [
        {
          query: GET_QUESTIONS,
        },
      ],
    }).then(() => {
      this.setState({ selectedQuestions: [] }, () => this.getQuestions())
    })
  }

  deleteFunction = async () => Promise.all(this.state.selectedQuestions.map(item => this.asyncDelete(item)))
  
  renderQuestions() {
    const { classes } = this.props;
    const { 
      _id,
      label,
      answers,
      correctAnswer,
      level,
      createdAt,
      questions,
      isLoading,
      error
    } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }
  
    if (error) {
      return <Typography variant="h6">Error! ${error.message}`;</Typography>
    }
    if (questions.length === 0) {
      return <Typography variant="h6">There are no questions</Typography>;
    }

    return (
      <QuestionsTable
        count={questions.count}
        onSelect={this.handleSelect}
        questions={questions.edges}
        show={{
          _id,
          label,
          answers,
          correctAnswer,
          level,
          createdAt,
        }}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { selectedQuestions } = this.state;

    return (
      <DashboardLayout title="Perguntas">
        <div className={classes.root}>
          <Toolbar
            addPath={'/question'}
            deleteFunction={this.deleteFunction}
            refresh={() => this.getQuestions()}
            selectedItems={selectedQuestions}
          />
          <div className={classes.content}>{this.renderQuestions()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

Questions.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Questions);
